import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm, Controller } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { PageTitle } from '../components/PageTitle';
import { USER_ROLES, USER_ROLE } from '../Constants';
import { useSmartService } from '../services/SmartService';
import { RootContext } from '../App';
import { SmartAlert } from '../components/SmartAlert';
import { hasRole, hasAnyRole, isSuperAdmin } from '../utils/SmartUtils';

const STATUS_ERROR = 1;
const STATUS_SUCCESS = 2;

// eslint-disable-next-line no-unused-vars
export function AddUserScreen(props) {
  const context = React.useContext(RootContext);
  const { getOrganizationsAutocomplete, createUser } = useSmartService();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [organizations, setOrganizations] = React.useState([]);
  const [status, setStatus] = React.useState(null);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    formState,
  } = useForm({ mode: 'onChange' });

  const onSubmit = async (data) => {
    console.log('form data  = ' + JSON.stringify(data));
    setIsSubmitting(true);
    try {
      let response = await createUser(
        data.fullName,
        data.email,
        data.password,
        data.organization,
        data.roles
      );
      console.log('response = ' + JSON.stringify(response));
      setStatus({
        code: STATUS_SUCCESS,
        message: 'The user has been successfully added.',
      });
    } catch (e) {
      console.error(e);
      setStatus({ code: STATUS_ERROR, message: e.message });
    }
    setIsSubmitting(false);
  };

  const roleFilter = (role) => {
    const roles = context.state.user.roles;
    return (
      role.id === USER_ROLE.USER.id ||
      (role.id === USER_ROLE.ORG_ADMIN.id &&
        hasAnyRole(roles, [
          USER_ROLE.SUPER_ADMIN.name,
          USER_ROLE.ORG_ADMIN.name,
        ])) ||
      (role.id === USER_ROLE.SUPER_ADMIN.id &&
        hasRole(roles, USER_ROLE.SUPER_ADMIN.name))
    );
  };

  React.useEffect(() => {
    (async () => {
      let response = await getOrganizationsAutocomplete();
      setOrganizations(response);
    })();
  }, []);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <PageTitle title='Add User' />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container xs={8} margin='auto'>
              {status !== null && (
                <Grid item xs={12}>
                  <SmartAlert
                    type={status.code === STATUS_ERROR ? 'error' : 'success'}
                    title={
                      status.code === STATUS_ERROR ? 'Error' : 'User Added'
                    }
                    message={status.message}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Box component='form' noValidate sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      margin='normal'
                      id='fullName'
                      label='Full Name'
                      name='fullName'
                      autoFocus
                      {...register('fullName', {
                        required: { value: true, message: 'Field is required' },
                      })}
                      error={errors.fullName ? true : false}
                      helperText={errors?.fullName?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='email'
                      label='Email Address'
                      name='email'
                      {...register('email', {
                        required: { value: true, message: 'Field is required' },
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: 'Email is invalid',
                        },
                      })}
                      error={errors.email ? true : false}
                      helperText={errors?.email?.message}
                    />
                  </Grid>
                  <Stack direction='row'>
                    <Grid item xs={6} sx={{ pr: 1 }}>
                      <TextField
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        label='Password'
                        type='password'
                        id='password'
                        {...register('password', {
                          required: {
                            value: true,
                            message: 'Field is required',
                          },
                        })}
                        error={errors.password ? true : false}
                        helperText={errors?.password?.message}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ pl: 1 }}>
                      <TextField
                        margin='normal'
                        required
                        fullWidth
                        name='confirm-password'
                        label='Confirm Password'
                        type='password'
                        id='confirm-password'
                        {...register('confirmPassword', {
                          required: {
                            value: true,
                            message: 'Field is required',
                          },
                          validate: (val) => {
                            if (watch('password') !== val) {
                              return 'Passwords do not match';
                            }
                          },
                        })}
                        error={errors.confirmPassword ? true : false}
                        helperText={errors?.confirmPassword?.message}
                      />
                    </Grid>
                  </Stack>
                  {isSuperAdmin(context.state.user.roles) ? (
                    <Grid item xs={12}>
                      <FormControl
                        required
                        fullWidth
                        error={errors.organization ? true : false}
                        sx={{ marginTop: 2 }}
                      >
                        <InputLabel id='org-select-label'>
                          Organization
                        </InputLabel>
                        <Controller
                          name='organization'
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              labelId='org-select-label'
                              label='Organization'
                            >
                              {organizations.map((o) => (
                                <MenuItem key={o.id} value={o.id}>
                                  {o.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.organization && (
                          <FormHelperText>Field is required.</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  ) : (
                    <input
                      type='hidden'
                      {...register('organization', {
                        required: true,
                        value: context.state.user.organizationId,
                      })}
                    />
                  )}
                  <Grid item xs={5}>
                    <FormControl
                      fullWidth
                      error={errors.roles ? true : false}
                      sx={{ marginTop: 3 }}
                    >
                      <InputLabel id='roles-select-label'>Roles</InputLabel>
                      <Controller
                        name='roles'
                        control={control}
                        defaultValue={[USER_ROLE.USER.id]}
                        render={({ field }) => (
                          <Select
                            {...field}
                            multiple
                            labelId='roles-select-label'
                            label='Roles'
                          >
                            {USER_ROLES.filter(roleFilter).map((ur) => (
                              <MenuItem key={ur.id} value={ur.name}>
                                {ur.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.roles && (
                        <FormHelperText>Field is required.</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/*<Grid item sx={{mt: 1}}>
                    <FormControlLabel
                      control={<Checkbox value="admin" color="primary" />}
                      label="Admin?"
                    />
                  </Grid>*/}
                  <Grid item sx={{ mt: 3 }} xs={3} margin='auto'>
                    {isSubmitting ? (
                      <LoadingButton
                        loading
                        fullWidth
                        variant='outlined'
                        loadingPosition='start'
                      >
                        Submitting...
                      </LoadingButton>
                    ) : (
                      <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        onClick={handleSubmit(onSubmit)}
                        disabled={!formState.isValid}
                      >
                        Submit
                      </Button>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}
