import * as React from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import Dashboard from './Dashboard';
import { SignInScreen } from './screens/SignInScreen';
import { Link as RouterLink } from 'react-router-dom';

// eslint-disable-next-line react/display-name
const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href} {...other} />;
});

LinkBehavior.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
};

const smartTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
  },
});

export const RootContext = React.createContext();

function App() {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      // eslint-disable-next-line default-case
      switch (action.type) {
      case 'SIGN_IN':
        localStorage.setItem(
          'auth',
          JSON.stringify({ user: action.user, token: action.token })
        );
        console.log(
          'signing in with user ' +
              JSON.stringify(action.user) +
              ' and token ' +
              action.token
        );
        return {
          ...prevState,
          isAuthenticated: true,
          user: action.user,
          token: action.token,
        };
      case 'SIGN_OUT':
        localStorage.clear();
        return {
          ...prevState,
          isAuthenticated: false,
        };
      case 'REFRESH':
        console.log(
          'refreshing auth with user ' +
              action.user +
              ' and token ' +
              action.token
        );
        return {
          ...prevState,
          isAuthenticated: true,
          user: action.user,
          token: action.token,
        };
      }
    },
    {
      isAuthenticated: false,
    }
  );

  const rootContext = {
    signIn: (user, token) =>
      dispatch({ type: 'SIGN_IN', user: user, token: token }),
    signOut: () => dispatch({ type: 'SIGN_OUT' }),
    refresh: (user, token) =>
      dispatch({ type: 'REFRESH', user: user, token: token }),
    state: state,
  };

  // Refresh auth data if it's available
  React.useEffect(() => {
    const data = localStorage.getItem('auth');
    if (data) {
      try {
        const { user, token } = JSON.parse(data);
        rootContext.refresh(user, token);
      } catch (e) {
        localStorage.clear();
      }
    }
  }, []);

  return (
    <ThemeProvider theme={smartTheme}>
      <RootContext.Provider value={rootContext}>
        <React.Fragment>
          {state.isAuthenticated ? <Dashboard /> : <SignInScreen />}
        </React.Fragment>
      </RootContext.Provider>
    </ThemeProvider>
  );
}

export default App;
