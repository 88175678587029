import { USER_ROLE, USER_ROLES, USER_STATUS } from '../Constants';

export function getAvatarInitials(fullName) {
  let names = fullName.split(' ');
  let result = '';
  if (names.length > 0) {
    result += names[0][0];
  }
  if (names.length > 1) {
    result += names[1][0];
  }
  return result;
}

export function isAdmin(roles) {
  return isSuperAdmin(roles) || isOrgAdmin(roles);
}

export function isSuperAdmin(roles) {
  return hasRole(roles, USER_ROLE.SUPER_ADMIN.name);
}

export function isOrgAdmin(roles) {
  return hasRole(roles, USER_ROLE.ORG_ADMIN.name);
}

export function hasRole(roles, checkRole) {
  for (let i = 0; i < roles.length; i++) {
    if (roles[i] === checkRole) {
      return true;
    }
  }
  return false;
}

export function hasAnyRole(roles, checkRoles) {
  for (let i = 0; i < checkRoles.length; i++) {
    let checkRole = checkRoles[i];
    if (hasRole(roles, checkRole)) {
      return true;
    }
  }
  return false;
}

export function hasAllRoles(roles, checkRoles) {
  for (let i = 0; i < checkRoles.length; i++) {
    let checkRole = checkRoles[i];
    if (!hasRole(roles, checkRole)) {
      return false;
    }
  }
  return true;
}

export function getUserRoleByName(name) {
  for (let i = 0; i < USER_ROLES.length; i++) {
    let role = USER_ROLES[i];
    if (role.name === name) {
      return role;
    }
  }
  return null;
}

export function getUserRolesByName(names) {
  let roles = [];
  for (let i = 0; i < names.length; i++) {
    let role = getUserRoleByName(names[i]);
    if (role !== null) {
      roles.push(role);
    }
  }
  return roles;
}

export function getUserStatus(status) {
  switch (status) {
  case USER_STATUS.PENDING.id: return USER_STATUS.PENDING.name;
  case USER_STATUS.ACTIVE.id: return USER_STATUS.ACTIVE.name;
  case USER_STATUS.INACTIVE.id: return USER_STATUS.INACTIVE.name;
  default: return 'Unknown';
  }
}
