import * as React from 'react';
import {RootContext} from '../App';
import {API_BASE_URL, USER_STATUS} from '../Constants';

export function useSmartService() {
  const context = React.useContext(RootContext);

  const createLogin = async (email, password) => {
    let response = await fetch(`${API_BASE_URL}logins`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });
    console.log('response = ' + JSON.stringify(response));
    if (!response.ok) {
      throw new Error('Login failed.');
    }
    let data = await response.json();
    return data;
  };

  const getOrganization = async id => {
    console.log('(remote) getting organization by id');
    let response = await fetch(`${API_BASE_URL}organizations/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    console.log('org data = ' + JSON.stringify(data));
    return data;
  };

  const createOrganization = async (
    name,
    streetAddress,
    city,
    state,
    postalCode,
    phone,
    adminFullName,
    adminEmail,
    adminPassword,
  ) => {
    let response = await fetch(`${API_BASE_URL}organizations`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        streetAddress: streetAddress,
        city: city,
        state: state,
        postalCode: postalCode,
        phone: phone,
        administrator: {
          fullName: adminFullName,
          password: adminPassword,
          email: adminEmail,
        },
      }),
    });
    console.log('response = ' + JSON.stringify(response));
    if (!response.ok) {
      if ([409].includes(response.status)) {
        throw new Error('The email specified is already in use.');
      } else {
        throw new Error('The registration could not be completed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const updateOrganization = async (
    id,
    name,
    streetAddress,
    city,
    state,
    postalCode,
    phone,
  ) => {
    console.log('(remote) updating organization');
    let response = await fetch(`${API_BASE_URL}organizations/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: JSON.stringify({
        name: name,
        streetAddress: streetAddress,
        city: city,
        state: state,
        postalCode: postalCode,
        phone: phone,
      }),
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const getMinesAutocomplete = async () => {
    let response = await fetch(`${API_BASE_URL}mines/autocomplete`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      throw new Error('Data fetch failed.');
    }
    let data = await response.json();
    return data;
  };

  const getMine = async id => {
    console.log('(remote) getting mine by id');
    let response = await fetch(`${API_BASE_URL}mines/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const getMines = async (scope = 'org') => {
    let response = await fetch(`${API_BASE_URL}mines?scope=${scope}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const createMine = async (
    organizationId,
    name,
    identifier,
    address = null,
    state = null,
    county = null,
  ) => {
    console.log('(remote) creating mine');
    let response = await fetch(`${API_BASE_URL}mines`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: JSON.stringify({
        organizationId: organizationId,
        name: name,
        identifier: identifier,
        address: address,
        state: state,
        county: county,
      }),
    });
    console.log('response = ' + JSON.stringify(response));
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    console.log('returning mine data ' + JSON.stringify(data));
    return data;
  };

  const updateMine = async (
    mineId,
    name,
    identifier,
    address = null,
    state = null,
    county = null,
  ) => {
    console.log('(remote) creating mine');
    let response = await fetch(`${API_BASE_URL}mines/${mineId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: JSON.stringify({
        name: name,
        identifier: identifier,
        address: address,
        state: state,
        county: county,
      }),
    });
    console.log('response = ' + JSON.stringify(response));
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    console.log('returning mine data ' + JSON.stringify(data));
    return data;
  };

  const deleteMine = async (id) => {
    let response = await fetch(`${API_BASE_URL}mines/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
  };

  const getOrganizationsAutocomplete = async () => {
    let response = await fetch(`${API_BASE_URL}organizations/autocomplete`, {
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error('Data fetch failed.');
    }
    let data = await response.json();
    return data;
  };

  const getOrganizations = async () => {
    let response = await fetch(`${API_BASE_URL}organizations`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const getRepair = async id => {
    console.log('(remote) getting repair by id');
    let response = await fetch(`${API_BASE_URL}repairs/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  // eslint-disable-next-line no-unused-vars
  const getRepairs = async (beginDate, endDate) => {
    console.log('(remote) getting repair by data span');
    let response = await fetch(`${API_BASE_URL}repairs?scope=org`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const getIncompleteRepairs = async () => {
    console.log('(remote) getting incomplete repairs');
    let response = await fetch(
      `${API_BASE_URL}repairs?scope=org&completed=false`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + context.state.token,
        },
      },
    );
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const getRepairStats = async chartType => {
    console.log('(remote) getting repair stats, type = ' + chartType);
    let response = await fetch(`${API_BASE_URL}repairs/stats?chartType=${chartType}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    },
    );
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const deleteRepairImage = async (repairId, imageId) => {
    console.log(
      '(remote) deleting repair iamge, repairId = ' +
        repairId +
        ', imageId = ' +
        imageId,
    );
    await fetch(`${API_BASE_URL}repairs/${repairId}/images/${imageId}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
  };

  const createRepairImage = async (repairId, uri) => {
    console.log('(remote) creating repair image');
    let filename = uri.substring(uri.lastIndexOf('/') + 1);
    let formData = new FormData();
    formData.append('image', {
      uri: uri,
      type: 'image/jpeg',
      name: filename,
    });
    let response = await fetch(`${API_BASE_URL}repairs/${repairId}/images`, {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: formData,
    });
    console.log('response = ' + JSON.stringify(response));
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    console.log('returning repair image data ' + JSON.stringify(data));
    return data;
  };

  const createRepair = async (
    mineId,
    uuid,
    title,
    notes = '',
    addedDate,
    dueDate,
    completedDate,
  ) => {
    console.log('(remote) creating repair');
    let response = await fetch(`${API_BASE_URL}repairs`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: JSON.stringify({
        mineId: mineId,
        uuid: uuid,
        title: title,
        notes: notes,
        addedDate: addedDate,
        dueDate: dueDate,
        completedDate: completedDate,
      }),
    });
    console.log('response = ' + JSON.stringify(response));
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    console.log('returning repair data ' + JSON.stringify(data));
    return data;
  };

  const updateRepair = async (
    id,
    mineId,
    uuid,
    title,
    notes = '',
    addedDate,
    dueDate,
    completedDate,
  ) => {
    console.log('(remote) updating repair');
    let response = await fetch(`${API_BASE_URL}repairs/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: JSON.stringify({
        mineId: mineId,
        uuid: uuid,
        title: title,
        notes: notes,
        addedDate: addedDate,
        dueDate: dueDate,
        completedDate: completedDate,
      }),
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const completeRepair = async (id, completedDate) => {
    console.log('(remote) completing repair');
    let response = await fetch(`${API_BASE_URL}repairs/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: JSON.stringify({
        completedDate: completedDate,
      }),
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const getCitation = async id => {
    console.log('(remote) getting citation by id');
    let response = await fetch(`${API_BASE_URL}citations/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const getCitations = async (beginDate, endDate) => {
    console.log('(remote) getting citation by data span');
    let response = await fetch(`${API_BASE_URL}citations?scope=org&dateBegin=${beginDate}&dateEnd=${endDate}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    },
    );
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    console.log('dat got = ' + JSON.stringify(data));
    return data;
  };

  const deleteCitationImage = async (citationId, imageId) => {
    console.log(
      '(remote) deleting citation iamge, citationId = ' +
      citationId +
        ', imageId = ' +
        imageId,
    );
    await fetch(`${API_BASE_URL}citations/${citationId}/images/${imageId}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
  };

  const createCitationImage = async (citationId, uri) => {
    console.log('(remote) creating citationId image');
    let filename = uri.substring(uri.lastIndexOf('/') + 1);
    let formData = new FormData();
    formData.append('image', {
      uri: uri,
      type: 'image/jpeg',
      name: filename,
    });
    let response = await fetch(
      `${API_BASE_URL}citations/${citationId}/images`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + context.state.token,
        },
        body: formData,
      },
    );
    console.log('response = ' + JSON.stringify(response));
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    console.log('returning citation image data ' + JSON.stringify(data));
    return data;
  };

  const createCitation = async (uuid, caseNumber, mineId, addedDate) => {
    console.log('(remote) creating citation');
    let response = await fetch(`${API_BASE_URL}citations`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: JSON.stringify({
        uuid: uuid,
        caseNumber: caseNumber,
        mineId: mineId,
        addedDate: addedDate,
      }),
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const updateCitation = async (id, uuid, caseNumber, mineId, addedDate) => {
    console.log('(remote) updating citation');
    let response = await fetch(`${API_BASE_URL}citations/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: JSON.stringify({
        uuid: uuid,
        caseNumber: caseNumber,
        mineId: mineId,
        addedDate: addedDate,
      }),
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const getActivity = async id => {
    console.log('(remote) getting activity by id');
    let response = await fetch(`${API_BASE_URL}activities/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const getActivities = async params => {
    console.log(
      '(remote) getting activities params = ' + JSON.stringify(params),
    );
    let apiUrl = `${API_BASE_URL}activities?scope=org`;
    if ('day' in params) {
      apiUrl += `&day=${params.day}`;
    } else if ('type' in params) {
      apiUrl += `&type=${params.type}`;
    }
    let response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    //console.log('remote activity data = ' + JSON.stringify(data));
    return data;
  };

  const createActivity = async (title, type, _data = '', addedAt) => {
    console.log('(remote) adding activity');
    let response = await fetch(`${API_BASE_URL}activities`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: JSON.stringify({
        title: title,
        type: type,
        data: _data,
        addedAt: addedAt,
      }),
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const checkAuthorization = async response => {
    if (response.status === 401) {
      context.signOut();
      return false;
    }
    return true;
  };

  const updateActivity = async (id, title, type, _data = '', addedAt) => {
    console.log('(remote) updating activity');
    let response = await fetch(`${API_BASE_URL}activities/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: JSON.stringify({
        title: title,
        type: type,
        data: _data,
        addedAt: addedAt,
      }),
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const updateActivityData = async (id, _data) => {
    console.log('(remote) updating activity data');
    let response = await fetch(`${API_BASE_URL}activities/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: JSON.stringify({
        data: _data,
      }),
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const getUser = async id => {
    console.log('(remote) getting user by id');
    let response = await fetch(`${API_BASE_URL}users/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const createUser = async (fullName, email, password, organizationId, roles) => {
    console.log('(remote) adding user, email = ' + email);
    let response = await fetch(`${API_BASE_URL}users`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: JSON.stringify({
        fullName: fullName,
        organizationId: organizationId,
        password: password,
        email: email,
        roles: roles,
        status: USER_STATUS.ACTIVE.id,
      }),
    });
    console.log('response = ' + JSON.stringify(response));
    if (!response.ok) {
      if ([400, 409].includes(response.status)) {
        throw new Error('The email specified is already in use.');
      } else {
        throw new Error('The registration could not be completed.');
      }
    }
    let data = await response.json();
    return data;
  };

  const updateUser = async (userId, fullName = null, email = null, password = null, roles = null) => {
    console.log('(remote) updating contact');
    let response = await fetch(`${API_BASE_URL}users/${userId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: JSON.stringify({
        fullName: fullName,
        email: email,
        password: password,
        roles: roles,
      }),
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        if ([400, 409].includes(response.status)) {
          throw new Error('The email specified is already in use.');
        } else {
          throw new Error('The contact info update could not be completed.');
        }
      }
    }
    let data = await response.json();
    return data;
  };

  const updateUserStatus = async (userId, status) => {
    console.log('(remote) updating status');
    let response = await fetch(`${API_BASE_URL}users/${userId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
      body: JSON.stringify({
        status: status,
      }),
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    console.log('user status data = ' + JSON.stringify(data));
    return data;
  };

  const approveUser = async (userId, decision) => {
    console.log('(remote) approving user');
    let response = await fetch(`${API_BASE_URL}users/${userId}/approve?decision=${decision}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
  };

  const deleteUser = async (id) => {
    let response = await fetch(`${API_BASE_URL}users/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
  };

  const getUsers = async (scope = 'org', recent = false) => {
    console.log('(remote) getting users');
    let response = await fetch(`${API_BASE_URL}users?scope=${scope}&recent=${recent}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context.state.token,
      },
    });
    if (!response.ok) {
      if (checkAuthorization(response)) {
        throw new Error('Data fetch failed.');
      }
    }
    let data = await response.json();
    return data;
  };

  return {
    createLogin,
    getOrganization,
    getOrganizations,
    createOrganization,
    updateOrganization,
    getMinesAutocomplete,
    getOrganizationsAutocomplete,
    getMine,
    getMines,
    createMine,
    updateMine,
    deleteMine,
    getRepair,
    getRepairs,
    getIncompleteRepairs,
    getRepairStats,
    createRepair,
    updateRepair,
    completeRepair,
    getCitation,
    getCitations,
    createCitation,
    updateCitation,
    getActivity,
    getActivities,
    createActivity,
    updateActivity,
    updateActivityData,
    getUser,
    createUser,
    updateUser,
    createRepairImage,
    deleteRepairImage,
    createCitationImage,
    deleteCitationImage,
    updateUserStatus,
    getUsers,
    approveUser,
    deleteUser,
  };
}
