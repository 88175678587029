import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SmartAlert } from '../components/SmartAlert';
import { PageTitle } from '../components/PageTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useSmartService } from '../services/SmartService';
import { isSuperAdmin, getUserStatus, isAdmin } from '../utils/SmartUtils';
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { USER_STATUS } from '../Constants';
import { Tooltip } from '@mui/material';
import { RootContext } from '../App';
import RefreshIcon from '@mui/icons-material/Refresh';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CloseIcon from '@mui/icons-material/Close';
import HowToRegIcon from '@mui/icons-material/HowToReg';

const STATUS_ERROR = 1;
const STATUS_SUCCESS = 2;

// eslint-disable-next-line no-unused-vars
export function ListUsersScreen(props) {
  let context = React.useContext(RootContext);
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [rows, setRows] = React.useState(null);
  const [refreshing, setRefreshing] = React.useState(false);
  const [refreshDisabled, setRefreshDisabled] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const { getUsers, approveUser, deleteUser, updateUserStatus } =
    useSmartService();
  const gridApiRef = useGridApiRef();

  const deleteAction = React.useCallback(
    (id) => async () => {
      if (
        window.confirm(
          'Are you sure you want to remove this user? Warning: Users cannot be removed if they are associated with any data.'
        )
      ) {
        try {
          await deleteUser(id);
        } catch (e) {
          setStatus({
            code: STATUS_ERROR,
            message: 'The user could not be removed.',
          });
          return;
        }
        setStatus({
          code: STATUS_SUCCESS,
          message: 'The user was succcessfully removed.',
        });
        await refresh();
      }
    },
    []
  );

  const editAction = React.useCallback(
    (id) => () => {
      navigate(`/edit-user/${id}`);
    },
    []
  );

  const deactivateAction = React.useCallback(
    (id) => async () => {
      try {
        await updateUserStatus(id, USER_STATUS.INACTIVE.id);
      } catch (e) {
        setStatus({
          code: STATUS_ERROR,
          message: 'The user could not be deactivated.',
        });
        return;
      }
      setStatus({
        code: STATUS_SUCCESS,
        message: 'The user was succcessfully deactivated.',
      });
      await refresh();
    },
    []
  );

  const activateAction = React.useCallback(
    (id) => async () => {
      try {
        await updateUserStatus(id, USER_STATUS.ACTIVE.id);
      } catch (e) {
        setStatus({
          code: STATUS_ERROR,
          message: 'The user could not be activated.',
        });
        return;
      }
      setStatus({
        code: STATUS_SUCCESS,
        message: 'The user was succcessfully activated.',
      });
      await refresh();
    },
    []
  );

  const approveAction = React.useCallback(
    (userId, decision) => async () => {
      if (!decision) {
        if (
          !window.confirm(
            'Are you sure you want to reject this user? Rejected users will be deleted.'
          )
        ) {
          return;
        }
      }
      try {
        await approveUser(userId, decision);
      } catch (e) {
        setStatus({
          code: STATUS_ERROR,
          message: 'The user could not be approved.',
        });
        return;
      }
      setStatus({
        code: STATUS_SUCCESS,
        message: 'The user was succcessfully approved.',
      });
      await refresh();
    },
    []
  );

  const columns = React.useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        align: 'center',
        flex: 1,
        headerAlign: 'center',
      },
      {
        field: 'fullName',
        flex: 1,
        headerName: 'Full name',
      },
      {
        field: 'email',
        flex: 1,
        headerName: 'E-mail Address',
        renderCell: (params) => (
          <Link href={'mailto:' + params.row.email}>{params.row.email}</Link>
        ),
      },
      {
        field: 'roles',
        flex: 1,
        headerName: 'Is Admin?',
        type: 'boolean',
        valueGetter: (params) => isAdmin(params.row.roles),
      },
      {
        field: 'createdAt',
        flex: 1,
        headerName: 'Date Created',
        type: 'dateTime',
        valueFormatter: (params) => {
          return moment(params.value).format('MMM D, YYYY');
        },
      },
      {
        field: 'status',
        flex: 1,
        headerName: 'Status',
        align: 'center',
        headerAlign: 'center',
        valueGetter: (params) => getUserStatus(params.value),
      },
      {
        field: 'lastLoginAt',
        flex: 1,
        headerName: 'Last Login',
        renderCell: (params) => {
          if (params.value === null) {
            return 'N/A';
          }
          return (
            <Tooltip
              title={moment(params.value).format('MMM D, YYYY h:mm:ss A')}
            >
              <span>{moment(params.value).format('MMM D, YYYY')}</span>
            </Tooltip>
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        flex: 1,
        getActions: (params) => {
          let actions = [
            <GridActionsCellItem
              key={0}
              icon={<PersonRemoveIcon />}
              label='Delete'
              onClick={deleteAction(params.id)}
            />,
            <GridActionsCellItem
              key={1}
              icon={<EditIcon />}
              label='Edit User'
              onClick={editAction(params.id)}
              showInMenu
            />,
          ];
          if (params.row.status === USER_STATUS.PENDING.id) {
            actions = actions.concat([
              <GridActionsCellItem
                key={2}
                icon={<HowToRegIcon />}
                label='Approve User'
                onClick={approveAction(params.id, true)}
                showInMenu
              />,
              <GridActionsCellItem
                key={3}
                icon={<DoNotDisturbIcon />}
                label='Reject User'
                onClick={approveAction(params.id, false)}
                showInMenu
              />,
            ]);
          } else if (params.row.status === USER_STATUS.INACTIVE.id) {
            actions.push(
              <GridActionsCellItem
                key={5}
                icon={<CheckCircleIcon />}
                label='Activate User'
                onClick={activateAction(params.id)}
                showInMenu
              />
            );
          } else if (params.row.status === USER_STATUS.ACTIVE.id) {
            actions.push(
              <GridActionsCellItem
                key={6}
                icon={<CloseIcon />}
                label='Deactivate User'
                onClick={deactivateAction(params.id)}
                showInMenu
              />
            );
          }
          return actions;
        },
      },
    ],
    []
  );

  const refresh = async () => {
    setRefreshDisabled(true);
    setRefreshing(true);
    let data = await getUsers(
      isSuperAdmin(context.state.user.roles) ? 'all' : 'org'
    );
    //console.log('data = ' + JSON.stringify(data));
    if (data) {
      setRows(data);
    }
    setRefreshing(false);
    setTimeout(() => {
      setRefreshDisabled(false);
    }, 3000);
  };

  React.useEffect(() => {
    (async () => {
      await refresh();
    })();
  }, []);

  if (!rows) {
    return (
      <Box sx={{ display: 'flex', mt: 5 }} margin='auto'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <PageTitle title='List Users' />
          {status !== null && (
            <SmartAlert
              type={status.code === STATUS_ERROR ? 'error' : 'success'}
              title={status.code === STATUS_ERROR ? 'Error' : 'Success'}
              message={status.message}
              onClose={() => setStatus(null)}
            />
          )}
          {searchParams.get('s') && (
            <SmartAlert
              type='success'
              title='Success'
              message='The user has been added successfully.'
            />
          )}
          <Grid
            container
            justify='flex-end'
            alignItems='center'
            justifyContent='right'
          >
            <Grid item xs={2} paddingLeft={1}>
              {refreshing && <CircularProgress size={20} />}
            </Grid>
            <Grid item xs={10}>
              <Stack direction='row' justifyContent='right'>
                <IconButton
                  aria-label='add'
                  disabled={refreshDisabled}
                  color='primary'
                  onClick={() => refresh()}
                >
                  <RefreshIcon />
                </IconButton>
                <IconButton
                  aria-label='add'
                  color='primary'
                  onClick={() => navigate('/add-user')}
                >
                  <PersonAddIcon />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
          <div style={{ height: 1000, width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  apiRef={gridApiRef}
                  initialState={{
                    pagination: {
                      pageSize: 25,
                    },
                    sorting: {
                      sortModel: [{ field: 'fullName', sort: 'asc' }],
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  rowsPerPageOptions={[10, 25, 50]}
                  disableSelectionOnClick
                  components={{ Toolbar: GridToolbar }}
                />
              </div>
            </div>
          </div>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}
