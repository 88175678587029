import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import PropTypes from 'prop-types';

export function SmartAlert(props) {
  const [open, setOpen] = React.useState(true);
  const { type, title, message, style } = props;

  const onTransitionEnd = (event) => {
    console.log('in test = ' + event.propertyName);
    if (event.propertyName === 'height') {
      props.onClose && props.onClose();
    }
  };

  return (
    <React.Fragment>
      <Collapse
        addEndListener={(node) => {
          node.addEventListener('transitionend', onTransitionEnd.bind(null), false);
        }}
        in={open}
        sx={{ marginTop: 1, marginBottom: 1 }}
      >
        <Alert
          severity={type}
          variant='outlined'
          onClose={() => setOpen(!open)}
          sx={style}
        >
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Collapse>
    </React.Fragment>
  );
}

SmartAlert.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  style: PropTypes.object,
  onClose: PropTypes.func,
};
