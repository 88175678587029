import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import { useSmartService } from '../services/SmartService';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import moment from 'moment';
import { getUserStatus } from '../utils/SmartUtils';

export default function RecentUsers() {
  const { getUsers } = useSmartService();
  let [users, setUsers] = React.useState([]);
  let [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      let data = await getUsers('all', true);
      setUsers(data);
      setIsLoading(false);
    })();
  }, []);

  return (
    <React.Fragment>
      <Title>Recent Users</Title>
      {isLoading ? (
        <Box sx={{ display: 'flex', mt: 5 }} margin='auto'>
          <CircularProgress />
        </Box>
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>Last Login</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.fullName}</TableCell>
                <TableCell><Link color="primary" href={'mailto:' + user.email}>{user.email}</Link></TableCell>
                <TableCell>{moment(user.createdAt).format('MM/DD/YYYY hh:mm:ss A')}</TableCell>
                <TableCell>{user.lastLoginAt === null ? 'N/A' : moment(user.lastLoginAt).format('MM/DD/YYYY hh:mm:ss A')}</TableCell>
                <TableCell>{getUserStatus(user.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <Link color="primary" href="/users" sx={{ mt: 3 }}>
        See more users
      </Link>
    </React.Fragment>
  );
}
