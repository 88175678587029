import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm, Controller } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { PageTitle } from '../components/PageTitle';
import { useSmartService } from '../services/SmartService';
import { RootContext } from '../App';
import { SmartAlert } from '../components/SmartAlert';
import { isSuperAdmin } from '../utils/SmartUtils';

const STATUS_ERROR = 1;
const STATUS_SUCCESS = 2;

// eslint-disable-next-line no-unused-vars
export function AddMineScreen(props) {
  const context = React.useContext(RootContext);
  const { getOrganizationsAutocomplete, createMine } = useSmartService();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [organizations, setOrganizations] = React.useState([]);
  const [status, setStatus] = React.useState(null);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    formState,
  } = useForm({ mode: 'onChange' });

  const onSubmit = async (data) => {
    console.log('form data  = ' + JSON.stringify(data));
    setIsSubmitting(true);
    try {
      let response = await createMine(
        data.organization,
        data.name,
        data.identifier,
        data.address,
        data.state,
        data.county,
      );
      console.log('response = ' + JSON.stringify(response));
      setStatus({
        code: STATUS_SUCCESS,
        message: 'The mine has been successfully added.',
      });
    } catch (e) {
      console.error(e);
      setStatus({ code: STATUS_ERROR, message: e.message });
    }
    setIsSubmitting(false);
  };

  React.useEffect(() => {
    (async () => {
      let response = await getOrganizationsAutocomplete();
      setOrganizations(response);
    })();
  }, []);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <PageTitle title='Add Mine' />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container xs={8} margin='auto'>
              {status !== null && (
                <Grid item xs={12}>
                  <SmartAlert
                    type={status.code === STATUS_ERROR ? 'error' : 'success'}
                    title={
                      status.code === STATUS_ERROR ? 'Error' : 'Mine Added'
                    }
                    message={status.message}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Box component='form' noValidate sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      margin='normal'
                      id='name'
                      label='Name'
                      name='name'
                      autoFocus
                      {...register('name', {
                        required: { value: true, message: 'Field is required' },
                      })}
                      error={errors.name ? true : false}
                      helperText={errors?.name?.message}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      margin='normal'
                      id='identifier'
                      label='Identifier'
                      name='identifier'
                      {...register('identifier', {
                        required: { value: true, message: 'Field is required' },
                      })}
                      error={errors.identifier ? true : false}
                      helperText={errors?.identifier?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      margin='normal'
                      id='address'
                      label='Address'
                      name='address'
                      {...register('address')}
                      error={errors.address ? true : false}
                      helperText={errors?.address?.message}
                    />
                  </Grid>
                  <Stack direction='row'>
                    <Grid item xs={6} sx={{ pr: 1 }}>
                      <TextField
                        margin='normal'
                        fullWidth
                        name='state'
                        label='State'
                        id='state'
                        {...register('state')}
                        error={errors.state ? true : false}
                        helperText={errors?.state?.message}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ pl: 1 }}>
                      <TextField
                        margin='normal'
                        fullWidth
                        name='county'
                        label='County'
                        id='county'
                        {...register('county')}
                        error={errors.county ? true : false}
                        helperText={errors?.county?.message}
                      />
                    </Grid>
                  </Stack>
                  {isSuperAdmin(context.state.user.roles) ? (
                    <Grid item xs={12}>
                      <FormControl
                        required
                        fullWidth
                        error={errors.organization ? true : false}
                        sx={{ marginTop: 2 }}
                      >
                        <InputLabel id='org-select-label'>
                          Organization
                        </InputLabel>
                        <Controller
                          name='organization'
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              labelId='org-select-label'
                              label='Organization'
                            >
                              {organizations.map((o) => (
                                <MenuItem key={o.id} value={o.id}>
                                  {o.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.organization && (
                          <FormHelperText>Field is required.</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  ) : (
                    <input
                      type='hidden'
                      {...register('organization', {
                        required: true,
                        value: context.state.user.organizationId,
                      })}
                    />
                  )}
                  <Grid item sx={{ mt: 3 }} xs={3} margin='auto'>
                    {isSubmitting ? (
                      <LoadingButton
                        loading
                        fullWidth
                        variant='outlined'
                        loadingPosition='start'
                      >
                        Submitting...
                      </LoadingButton>
                    ) : (
                      <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        onClick={handleSubmit(onSubmit)}
                        disabled={!formState.isValid}
                      >
                        Submit
                      </Button>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}
