import * as React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export function PageTitle(props) {
  return (
    <Typography margin="auto" component="h2" variant="h6" color="primary" gutterBottom>
      {props.title}
    </Typography>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};