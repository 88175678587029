import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SmartAlert } from '../components/SmartAlert';
import { PageTitle } from '../components/PageTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useSmartService } from '../services/SmartService';
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const STATUS_ERROR = 1;
const STATUS_SUCCESS = 2;

// eslint-disable-next-line no-unused-vars
export function ListOrganizationsScreen(props) {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [rows, setRows] = React.useState(null);
  const [refreshing, setRefreshing] = React.useState(false);
  const [refreshDisabled, setRefreshDisabled] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const { getOrganizations, deleteMine } = useSmartService();
  const gridApiRef = useGridApiRef();

  const deleteAction = React.useCallback(
    (id) => async () => {
      if (
        window.confirm(
          'Are you sure you want to remove this organization? Warning: Organization cannot be removed if they are associated with any data.'
        )
      ) {
        try {
          await deleteMine(id);
        } catch (e) {
          setStatus({
            code: STATUS_ERROR,
            message: 'The organization could not be deleted.',
          });
          return;
        }
        setStatus({
          code: STATUS_SUCCESS,
          message: 'The organization was succcessfully deleted.',
        });
        await refresh();
      }
    },
    []
  );

  const editAction = React.useCallback(
    (id) => () => {
      navigate(`/edit-org/${id}`);
    },
    []
  );

  const emptyFormatter = params => {
    return params.value === null || params.value === '' ? 'N/A' : params.value;
  };

  const columns = React.useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        align: 'center',
        flex: 1,
        headerAlign: 'center',
      },
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
      },
      {
        field: 'city',
        flex: 1,
        headerName: 'City',
        valueFormatter: emptyFormatter,
      },
      {
        field: 'state',
        flex: 1,
        headerName: 'State',
        valueFormatter: emptyFormatter,
      },
      {
        field: 'postalCode',
        flex: 1,
        headerName: 'Postal Code',
        valueFormatter: emptyFormatter,
      },
      {
        field: 'createdAt',
        flex: 1,
        headerName: 'Date Created',
        renderCell: (params) => {
          if (params.value === null) {
            return 'N/A';
          }
          return (
            <Tooltip
              title={moment(params.value).format('MMM D, YYYY h:mm:ss A')}
            >
              <span>{moment(params.value).format('MMM D, YYYY')}</span>
            </Tooltip>
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        flex: 1,
        getActions: (params) => {
          let actions = [
            <GridActionsCellItem
              key={0}
              icon={<DeleteIcon />}
              label='Delete'
              onClick={deleteAction(params.id)}
            />,
            <GridActionsCellItem
              key={1}
              icon={<EditIcon />}
              label='Edit Organization'
              onClick={editAction(params.id)}
              showInMenu
            />,
          ];
          return actions;
        },
      },
    ],
    []
  );

  const refresh = async () => {
    setRefreshDisabled(true);
    setRefreshing(true);
    let data = await getOrganizations();
    //console.log('data = ' + JSON.stringify(data));
    if (data) {
      setRows(data);
    }
    setRefreshing(false);
    setTimeout(() => {
      setRefreshDisabled(false);
    }, 3000);
  };

  React.useEffect(() => {
    (async () => {
      await refresh();
    })();
  }, []);

  if (!rows) {
    return (
      <Box sx={{ display: 'flex', mt: 5 }} margin='auto'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <PageTitle title='List Organizations' />
          {status !== null && (
            <SmartAlert
              type={status.code === STATUS_ERROR ? 'error' : 'success'}
              title={status.code === STATUS_ERROR ? 'Error' : 'Success'}
              message={status.message}
              onClose={() => setStatus(null)}
            />
          )}
          {searchParams.get('s') && (
            <SmartAlert
              type='success'
              title='Success'
              message='The mine has been added successfully.'
            />
          )}
          <Grid
            container
            justify='flex-end'
            alignItems='center'
            justifyContent='right'
          >
            <Grid item xs={2} paddingLeft={1}>
              {refreshing && <CircularProgress size={20} />}
            </Grid>
            <Grid item xs={10}>
              <Stack direction='row' justifyContent='right'>
                <IconButton
                  aria-label='add'
                  disabled={refreshDisabled}
                  color='primary'
                  onClick={() => refresh()}
                >
                  <RefreshIcon />
                </IconButton>
                <IconButton
                  aria-label='add'
                  color='primary'
                  onClick={() => navigate('/add-org')}
                >
                  <AddIcon />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
          <div style={{ height: 1000, width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  apiRef={gridApiRef}
                  initialState={{
                    pagination: {
                      pageSize: 25,
                    },
                    sorting: {
                      sortModel: [{ field: 'fullName', sort: 'asc' }],
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  rowsPerPageOptions={[10, 25, 50]}
                  disableSelectionOnClick
                  components={{ Toolbar: GridToolbar }}
                />
              </div>
            </div>
          </div>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}
