import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { PageTitle } from '../components/PageTitle';
import { useSmartService } from '../services/SmartService';
import { SmartAlert } from '../components/SmartAlert';
import { useParams } from 'react-router-dom';

const STATUS_ERROR = 1;
const STATUS_SUCCESS = 2;

// eslint-disable-next-line no-unused-vars
export function EditMineScreen(props) {
  const { mineId } = useParams();
  const { getMine, updateMine } = useSmartService();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [mine, setMine] = React.useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setValue,
  } = useForm({ mode: 'onChange' });

  const onSubmit = async (data) => {
    console.log('form data  = ' + JSON.stringify(data));
    setIsSubmitting(true);
    try {
      let response = await updateMine(
        mineId,
        data.name,
        data.identifier,
        data.address,
        data.state,
        data.county,
      );
      console.log('response = ' + JSON.stringify(response));
      setStatus({
        code: STATUS_SUCCESS,
        message: 'The mine has been successfully updated.',
      });
    } catch (e) {
      console.error(e);
      setStatus({ code: STATUS_ERROR, message: e.message });
    }
    setIsSubmitting(false);
  };

  React.useEffect(() => {
    (async () => {
      let data = await getMine(mineId);
      console.log('mine = ' + JSON.stringify(data));
      setValue('name', data.name, { shouldValidate: true });
      setValue('identifier', data.identifier, { shouldValidate: true });
      setValue('address', data.address, { shouldValidate: true });
      setValue('state', data.state, { shouldValidate: true });
      setValue('county', data.county, { shouldValidate: true });
      setMine(data);
    })();
  }, []);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <PageTitle title='Edit Mine' />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container xs={8} margin='auto'>
              {status !== null && (
                <Grid item xs={12}>
                  <SmartAlert
                    type={status.code === STATUS_ERROR ? 'error' : 'success'}
                    title={
                      status.code === STATUS_ERROR ? 'Error' : 'Mine Added'
                    }
                    message={status.message}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Box component='form' noValidate sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      margin='normal'
                      id='name'
                      label='Name'
                      name='name'
                      autoFocus
                      {...register('name', {
                        required: { value: true, message: 'Field is required' },
                      })}
                      error={errors.name ? true : false}
                      helperText={errors?.name?.message}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      margin='normal'
                      id='identifier'
                      label='Identifier'
                      name='identifier'
                      {...register('identifier', {
                        required: { value: true, message: 'Field is required' },
                      })}
                      error={errors.identifier ? true : false}
                      helperText={errors?.identifier?.message}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      margin='normal'
                      id='address'
                      label='Address'
                      name='address'
                      {...register('address')}
                      error={errors.address ? true : false}
                      helperText={errors?.address?.message}
                      {...(mine?.address && {InputLabelProps: { shrink: true }})}
                    />
                  </Grid>
                  <Stack direction='row'>
                    <Grid item xs={6} sx={{ pr: 1 }}>
                      <TextField
                        margin='normal'
                        fullWidth
                        name='state'
                        label='State'
                        id='state'
                        {...register('state')}
                        error={errors.state ? true : false}
                        helperText={errors?.state?.message}
                        {...(mine?.state && {InputLabelProps: { shrink: true }})}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ pl: 1 }}>
                      <TextField
                        margin='normal'
                        fullWidth
                        name='county'
                        label='County'
                        id='county'
                        {...register('county')}
                        error={errors.county ? true : false}
                        helperText={errors?.county?.message}
                        {...(mine?.county && {InputLabelProps: { shrink: true }})}
                      />
                    </Grid>
                  </Stack>
                  <Grid item sx={{ mt: 3 }} xs={3} margin='auto'>
                    {isSubmitting ? (
                      <LoadingButton
                        loading
                        fullWidth
                        variant='outlined'
                        loadingPosition='start'
                      >
                        Submitting...
                      </LoadingButton>
                    ) : (
                      <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        onClick={handleSubmit(onSubmit)}
                        disabled={!formState.isValid}
                      >
                        Submit
                      </Button>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}
