import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useForm } from 'react-hook-form';
import { RootContext } from '../App';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSmartService } from '../services/SmartService';
import { SmartAlert } from '../components/SmartAlert';
import { isAdmin } from '../utils/SmartUtils';

export function SignInScreen() {
  const context = React.useContext(RootContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isAuthFailed, setIsAuthFailed] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm({ mode: 'onChange' });
  const { createLogin } = useSmartService();

  const onSubmit = async (data) => {
    console.log(data);
    setIsSubmitting(true);
    try {
      let result = await createLogin(data.email, data.password);
      if (isAdmin(result.user.roles)) {
        context.signIn(result.user, result.token);
      } else {
        throw Error('User is not an admin');
      }
    } catch (e) {
      setIsAuthFailed(true);
      setIsSubmitting(false);
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          {isAuthFailed && (
            <SmartAlert
              type='error'
              title='Sign In Failed'
              message='The email address and/or password specified could not be authenticated.'
              style={{ mt: 1, mb: 2 }}
            />
          )}
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            autoFocus
            {...register('email', { required: true })}
            error={errors.email ? true : false}
            helperText={errors.email ? 'Field is required.' : null}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            {...register('password', { required: true })}
            error={errors.password ? true : false}
            helperText={errors.password ? 'Field is required.' : null}
          />
          <FormControlLabel
            control={<Checkbox value='remember' color='primary' />}
            label='Remember me'
          />
          {isSubmitting ? (
            <LoadingButton
              loading
              fullWidth
              variant='outlined'
              loadingPosition='start'
              sx={{ mt: 3, mb: 2 }}
            >
              Signing In...
            </LoadingButton>
          ) : (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
              disabled={!formState.isValid}
            >
              Sign In
            </Button>
          )}
          <Grid container>
            <Grid item xs>
              <Link href='#' variant='body2'>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href='#' variant='body2'>
                {'Don\'t have an account? Sign Up'}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
